// action - state management
import { SET_PODS, SET_MUSIC, SET_PUBLICATIONS, SET_NEW_USERS, SET_ACTIVE_USERS, STATION_CHANGED } from './actions';

export const initialState = {
    fetchedPodcasts: false,
    fetchedNews: false,
    fetchedMusic: false,
    podcasts: [],
    music: [],
    news: [],
    newUsers: [],
    activeUsers: []
};

// -----------------------|| ACCOUNT REDUCER ||-----------------------//

const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PODS: {
            const { podcasts } = action.payload;
            return {
                ...state,
                fetchedPodcasts: true,
                podcasts
            };
        }
        case SET_MUSIC: {
            const { music } = action.payload;
            return {
                ...state,
                music,
                fetchedMusic: true
            };
        }
        case SET_PUBLICATIONS: {
            const { news } = action.payload;
            return {
                ...state,
                fetchedNews: true,
                news
            };
        }
        case SET_NEW_USERS: {
            const { users } = action.payload;
            return {
                ...state,
                newUsers: users
            };
        }
        case SET_ACTIVE_USERS: {
            const { users } = action.payload;
            return {
                ...state,
                activeUsers: users
            };
        }
        case STATION_CHANGED: {
          return {
              ...state,
              fetchedPodcasts: false,
              fetchedNews: false,
              fetchedMusic: false,
              podcasts: [],
              music: [],
              news: [],
          };
      }
        default: {
            return { ...state };
        }
    }
};

export default contentReducer;
