// action - state management
import { CLEAR_RENTAL_TRANSACTIONS, RENTAL_PAID, SET_RENTALS, SET_RENTAL_TRANSACTIONS, CLEAR_RENTALS, SET_RENTAL_SUMMARY, GET_RENTAL_SUMMARY } from './actions';

export const initialState = {
    rentals: {
      count: 0,
      next: "",
      previous: "",
      results: []
    },
    rentalSummary:{
      "airtel" : 0,
      "mtn" : 0,
      "dpo" : {'usd':0,'ugx':0}
    },
    rentalTransactions: [],
    fetchedRentals: false,
    fetchedRentalTransactions: false,
    rentalSummaryFetched: false,
};

// -----------------------|| ACCOUNT REDUCER ||-----------------------//

const rentalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RENTAL_TRANSACTIONS: {
            const { transactions } = action.payload;
            return {
                ...state,
                fetchedRentalTransactions: true,
                rentalTransactions : transactions
            };
        }
        case SET_RENTAL_SUMMARY: {
          const { summary } = action.payload;
          return {
              ...state,
              rentalSummaryFetched: true,
              rentalSummary : summary
          };
        }
        case GET_RENTAL_SUMMARY: {
          return {
              ...state,
              rentalSummaryFetched: false,
              rentalSummary: {
                "airtel" : 0,
                "mtn" : 0,
                "dpo" : {'usd':0,'ugx':0}
              },
          };
        }
        case SET_RENTALS: {
          const { rentals } = action.payload;
          console.log(`received rental data ${rentals}`)
          return {
              ...state,
              fetchedRentals: true,
              rentals: rentals
          };
        }
        case RENTAL_PAID: {
          const { rental } = action.payload;
          return {
            ...state,
            rentals: {
              count: state.rentals.count,
              next: state.rentals.next,
              previous: state.rentals.previous,
              results: state.rentals.results.map(rentalB => {
                if(rentalB.id === rental.id){
                  return rental
                }
                return rentalB
              })
            }
          };
        }
        case CLEAR_RENTAL_TRANSACTIONS: {
          return {
            ...state,
            fetchedRentslTransactions: false,
            rentalTransactions: []
          };
        }
        case CLEAR_RENTALS: {
          return {
            ...state,
            fetchedRentals: false,
            rentals: {
              count: 0,
              next: "",
              previous: "",
              results: []
            }
          };
        }
        default: {
            return { ...state };
        }
    }
};

export default rentalReducer;
