// action - state management
import { CLEAR_TRANSACTIONS, PAID, SET_SUBSCRIPTIONS, SET_TRANSACTIONS, CLEAR_SUBSCRIPTIONS, SET_SUMMARY, GET_SUMMARY } from './actions';

export const initialState = {
    subscriptions: {
      count: 0,
      next: "",
      previous: "",
      results: []
    },
    summary:{
      "airtel" : 0,
      "mtn" : 0,
      "dpo" : {'usd':0,'ugx':0}
    },
    transactions: [],
    fetchedSubscriptions: false,
    fetchedTransactions: false,
    summaryFetched: false,
};

const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSACTIONS: {
            const { transactions } = action.payload;
            return {
                ...state,
                fetchedTransactions: true,
                transactions
            };
        }
        case SET_SUMMARY: {
          const { summary } = action.payload;
          return {
              ...state,
              summaryFetched: true,
              summary
          };
        }
        case GET_SUMMARY: {
          return {
              ...state,
              summaryFetched: false,
              summary: {
                "airtel" : 0,
                "mtn" : 0,
                "dpo" : {'usd':0,'ugx':0}
              },
          };
        }
        case SET_SUBSCRIPTIONS: {
          const { subscriptions } = action.payload;
          return {
              ...state,
              fetchedSubscriptions: true,
              subscriptions
          };
        }
        case PAID: {
          const { subscription } = action.payload;
          return {
            ...state,
            subscriptions: {
              count: state.subscriptions.count,
              next: state.subscriptions.next,
              previous: state.subscriptions.previous,
              results: state.subscriptions.results.map(sub => {
                if(sub.user_id === subscription.user_id){
                  return subscription
                }
                return sub
              })
            }
          };
        }
        case CLEAR_TRANSACTIONS: {
          return {
            ...state,
            fetchedTransactions: false,
            transactions: []
          };
        }
        case CLEAR_SUBSCRIPTIONS: {
          return {
            ...state,
            fetchedSubscriptions: false,
            subscriptions: {
              count: 0,
              next: "",
              previous: "",
              results: []
            }
          };
        }
        default: {
            return { ...state };
        }
    }
};

export default contentReducer;
