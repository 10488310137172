// action - state management
import { SET_TV, SET_RADIO, SET_QUICKIES, SET_VIDEOS, STATION_CHANGED, LOADING_VIEWS, SET_ACTIVE, SET_ADS, SET_WATCH_TIME, SET_DAILY_VIEWS, SET_HOURLY_VIEWS, SET_ACTIVE_VIEWERS} from './actions';

export const initialState = {
    fetchedTv: false,
    fetchedRadio: false,
    fetchedVideos: false,
    activeViewers: 'N/A',
    watchTime: 'N/A',
    dailyViews: [],
    hourlyViews: [],
    loadingHourly: false,
    loadingDaily: false,
    fetchedQuickies: false,
    activeStation: '',
    tvStations: [],
    radioStations: [],
    views: [],
    quickies: [],
    ads: [],
    fetchedAds: false,
    videos: [],
    tunedIn: "-",
};

// -----------------------|| ACCOUNT REDUCER ||-----------------------//

const stationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TV: {
            const { stations } = action.payload;
            return {
                ...state,
                fetchedTv: true,
                tvStations: stations
            };
        }
        case SET_ACTIVE: {
          const { station } = action.payload;
          return {
              ...state,
              activeStation: station
          };
      }
      case LOADING_VIEWS: {
        const { isHourly } = action.payload;
        return isHourly ? {
            ...state,
            loadingHourly: true
        } : {
          ...state,
            loadingDaily: true
        };
      }
      case SET_RADIO: {
          const { stations } = action.payload;
          return {
              ...state,
              radioStations: stations,
              fetchedRadio: true
          };
      }
      case SET_WATCH_TIME: {
          const { watchTime } = action.payload;
          return {
              ...state,
              watchTime
          };
      }

      case SET_DAILY_VIEWS: {
        const { views } = action.payload;
        return {
            ...state,
            loadingDaily: false,
            dailyViews: views
        };
      }
      case SET_HOURLY_VIEWS: {
        const { views } = action.payload;
        return {
            ...state,
            loadingHourly: false,
            hourlyViews: views
        };
      }
      case SET_ACTIVE_VIEWERS: {
        const { viewers } = action.payload;
        return {
            ...state,
            activeViewers: viewers
        };
      }
      case SET_QUICKIES: {
          const { quickies } = action.payload;
          return {
              ...state,
              fetchedQuickies: true,
              quickies
          };
      }
      case SET_VIDEOS: {
          const { videos } = action.payload;
          return {
              ...state,
              fetchedVideos: true,
              videos
          };
      }
      case STATION_CHANGED: {
        return {
            ...state,
            fetchedVideos: false,
            fetchedQuickies: false,
            fetchedRadio: false,
            fetchedTv: false,
            fetchedAds:false,
            views: [],
            quickies: [],
            videos: [],
            tunedIn: "-"
        };
      }
      case SET_ADS: {
        const { ads } = action.payload;
        return {
          ...state,
          fetchedAds: true,
          ads
        }
      }
      default: {
          return { ...state };
      }
    }
};

export default stationsReducer;
