// action - state management
import { GET_REPORT, SET_REPORT, REPORT_ERROR, CLEAR_REPORT } from './actions';

export const initialState = {
    reportData: null,
    reportError: false,
    fetchingReport:false,
    reportFetched:false,
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORT: {
          return {
              ...state,
              reportError: false,
              fetchingReport: true,
              reportFetched: false,
          };
        }
        case REPORT_ERROR: {
          return {
              ...state,
              fetchingReport: false,
              reportFetched: true,
              reportError: true,
          };
        }
        case SET_REPORT: {
          const { csv } = action.payload;
          return {
              ...state,
              fetchingReport: false,
              reportFetched: true,
              reportData: csv
          };
        }
        case CLEAR_REPORT: {
          return {
            ...state,
            reportData: null,
            reportError: false,
            fetchingReport:false,
            reportFetched:false,
          }
        }
        default: {
            return { ...state };
        }
    }
};

export default reportReducer;
