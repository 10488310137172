require('dotenv').config();
const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    apiServer:  process.env.REACT_APP_API_SERVER,
    afroServer: process.env.REACT_APP_AFRO_SERVER,

    /* test */
    // apiServer:  `http://13.245.201.83:3000/`,
    // afroServer: `https://admin.afromobile.com/api/v1/`,

    /* production */
    // apiServer:  `https://dashsv.afromobile.com/`,
    // afroServer: `https://mobilemedia.co.ug/api/v1/`,

    /* local */
    // afroServer: `http://localhost:4000/api/v1/`,

    dateFormat: "YYYY-MM-DD'T'HH:mm:ss.SSSSSS",
    dateSubscribedFormat: "YYYY-MM-DDTHH:mm:ss.SSS"
};

console.log(`api server >>> ${config.afroServer}`)

export default config;
