// action - state management
import { CLEAR_BUNDLE_TRANSACTIONS, BUNDLE_PAID, SET_BUNDLE_SUBSCRIPTIONS, SET_BUNDLE_TRANSACTIONS, CLEAR_BUNDLE_SUBSCRIPTIONS, SET_BUNDLE_SUMMARY, GET_BUNDLE_SUMMARY } from './actions';

export const initialState = {
    bundleSubscriptions: {
      count: 0,
      next: "",
      previous: "",
      results: []
    },
    bundleSummary:{
      "total" : 0,
    },
    bundleTransactions: [],
    fetchedBundleSubscriptions: false,
    fetchedBundleTransactions: false,
    bundleSummaryFetched: false,
};

// -----------------------|| ACCOUNT REDUCER ||-----------------------//

const bundleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUNDLE_TRANSACTIONS: {
          console.log("received set bundle transactions")
            const { transactions } = action.payload;
            return {
                ...state,
                fetchedBundleTransactions: true,
                bundleTransactions : transactions
            };
        }
        case SET_BUNDLE_SUMMARY: {
          const { summary } = action.payload;
          return {
              ...state,
              bundleSummaryFetched: true,
              bundleSummary : summary
          };
        }
        case GET_BUNDLE_SUMMARY: {
          return {
              ...state,
              bundleSummaryFetched: false,
              bundleSummary: {
                "total" : 0,
              },
          };
        }
        case SET_BUNDLE_SUBSCRIPTIONS: {
          const { bundleSubscriptions } = action.payload;
          return {
              ...state,
              fetchedBundleSubscriptions: true,
              bundleSubscriptions: bundleSubscriptions
          };
        }
        case BUNDLE_PAID: {
          const { bundleSubscription } = action.payload;
          return {
            ...state,
            bundleSubscriptions: {
              count: state.bundleSubscriptions.count,
              next: state.bundleSubscriptions.next,
              previous: state.bundleSubscriptions.previous,
              results: state.bundleSubscriptions.results.map(bundleSubscriptionB => {
                if(bundleSubscriptionB.id === bundleSubscription.id){
                  return bundleSubscription
                }
                return bundleSubscriptionB
              })
            }
          };
        }
        case CLEAR_BUNDLE_TRANSACTIONS: {
          return {
            ...state,
            fetchedBundleTransactions: false,
            bundleTransactions: []
          };
        }
        case CLEAR_BUNDLE_SUBSCRIPTIONS: {
          return {
            ...state,
            fetchedBundleSubscriptions: false,
            bundleSubscriptions: {
              count: 0,
              next: "",
              previous: "",
              results: []
            }
          };
        }
        default: {
            return { ...state };
        }
    }
};

export default bundleReducer;
