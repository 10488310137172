// assets
import { IconCurrencyBitcoin } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const moderator = {
  id: 'mod-group',
  title: 'Mod',
  type: 'group',
  children: [
      {
        id: 'payments',
        title: 'Payments',
        type: 'item',
        url: '/payments',
        icon: IconCurrencyBitcoin,
        breadcrumbs: true
    }
  ]
};

export default moderator;
