import axios from 'axios';
import configData from '../config';
import {
    SET_TV,
    SET_RADIO,
    SET_USERS,
    SET_WATCH_TIME,
    SET_DAILY_VIEWS,
    SET_HOURLY_VIEWS,
    SET_ACTIVE_VIEWERS,
    SET_QUICKIES,
    SET_VIDEOS,
    SET_PUBLICATIONS,
    SET_MUSIC,
    SET_PODS,
    SET_ACTIVE_USERS,
    SET_NEW_USERS,
    REGISTER,
    PAID,
    LOADING_VIEWS,
    SET_SUBSCRIPTIONS,
    SET_TRANSACTIONS,
    SET_SUMMARY,
    GET_SUMMARY,
    SET_RENTALS,
    SET_RENTAL_SUMMARY,
    GET_RENTAL_SUMMARY,
    SET_RENTAL_TRANSACTIONS,
    RENTAL_PAID,
    SET_STATS,
    SET_SHOWS,
    SET_SHOW_STATS,
    SET_SEASONS,
    SET_EPISODES,
    LOGOUT,
    SET_ADS,
    SET_MOVIES,
    SET_BUNDLE_SUBSCRIPTIONS,
    SET_BUNDLE_TRANSACTIONS,
    BUNDLE_PAID,
    GET_BUNDLE_SUMMARY,
    SET_BUNDLE_SUMMARY,
    GET_REPORT, SET_REPORT, REPORT_ERROR, CLEAR_REPORT,
    EVENT_PAID, SET_EVENT_PAYMENTS, SET_EVENT_PAYMENT_TRANSACTIONS, SET_EVENT_PAYMENT_SUMMARY, GET_EVENT_PAYMENT_SUMMARY,SET_EVENTS,GOT_STATS
} from 'store/actions';

export const handleLogout = async (dispatch, token) => {
  axios
      .post(`${configData.apiServer}users/logout`, {}, { headers: { Authorization: `${token}` } })
      .then(() => {
          // Force the LOGOUT
      })
      .catch((error) => {
          console.log('error - ', error);
      });
  dispatch({ type: LOGOUT });
};

export const formatDuration = (durationInMs) => {
  let seconds = (durationInMs / 1000)
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${formattedHours}:${formattedMinutes}:${Math.round(formattedSeconds)}`;
}

export const downloadReport = (dispatch, reportData) => {
  const filename = reportData.headers["content-disposition"].split('"')[1];
  const url = window.URL.createObjectURL(new Blob([reportData.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
  dispatch({
    type: CLEAR_REPORT
  });
}

export const apiModel = {
    analytics: {
        getAnalyticsData(dispatch, dimension, metric, startdate, enddate, token) {
            axios
                .get(`${configData.apiServer}analytics?dimension=${dimension}&metric=${metric}&startdate=${startdate}&enddate=${enddate}`, {
                  headers: {
                      Authorization: `Bearer ${token}`
                  }
              })
                .then((response) => {

                    switch (metric) {
                        case 'activeUsers':
                            dispatch({
                                type: SET_ACTIVE_USERS,
                                payload: { users: response.data.results }
                            });
                            break;

                        case 'newUsers':
                            dispatch({
                                type: SET_NEW_USERS,
                                payload: { users: response.data.results }
                            });
                            break;

                        default:
                            break;
                    }
                })
                .catch((error) => {
                  if(error.message.includes("401")){
                    console.log("logout");
                    handleLogout(dispatch,token)
                  }
                    console.log(`getAnalyticsData error ::::: ${(error.message)}`);
                });
        },
        getRealTimeAnalyticsData(dispatch, dimension, metric, token) {
            axios
                .get(`${configData.apiServer}analytics?dimension=${dimension}&metric=${metric}`, {
                  headers: {
                      Authorization: `Bearer ${token}`
                  }
              })
                .then((response) => {
                    switch (metric) {
                        case 'activeUsers':
                            dispatch({
                                type: SET_ACTIVE_USERS,
                                payload: { users: response.data.results }
                            });
                            break;

                        // case 'newUsers':
                        //     dispatch({
                        //         type: SET_NEW_USERS,
                        //         payload: { users: response.data.results }
                        //     });
                        //     break;

                        default:
                            break;
                    }
                })
                .catch((error) => {
                    console.log(`getAnalyticsData error ::::: ${JSON.stringify(error)}`);
                });
        }
    },
    stations: {
        getTvStations(dispatch, stDate, enDate, show = false) {
          let baseUrl = `${configData.afroServer}stations/tv/all`
          if(show){
            baseUrl += `?show=true`
          }
          if(stDate  && enDate && show){
            baseUrl += `&start=${stDate}&end=${enDate}`
          }else{
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  dispatch({
                      type: SET_TV,
                      payload: { stations: response.data.data.results }
                  });
              })
              .catch((error) => {
                  console.log(`tv station response error ::::: ${JSON.stringify(error)}`);
              });
        },
        async getStation(id) {
          let baseUrl = `${configData.afroServer}stations/${id}`
          return axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  return response.data
              })
              .catch((error) => {
                  console.log(`tv station response error ::::: ${JSON.stringify(error)}`);
                  return null
              });
        },
        async getStationLikesCount(id) {
          let baseUrl = `${configData.afroServer}likes/station/${id}/likedCount`
          return axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  return response.data.count
              })
              .catch((error) => {
                  console.log(` station likes count error ::::: ${JSON.stringify(error)}`);
                  return null
              });
        },
        async getStationDisLikesCount(id) {
          let baseUrl = `${configData.afroServer}likes/station/${id}/dislikedCount`
          return axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  return response.data.count
              })
              .catch((error) => {
                  console.log(` station likes count error ::::: ${JSON.stringify(error)}`);
                  return null
              });
        },
        getRadioStations(dispatch, stDate, enDate, show = false) {
          let baseUrl = `${configData.afroServer}stations/radio/all`
          if(show){
            baseUrl += `?show=true`
          }
          if(stDate  && enDate && show){
            baseUrl += `&start=${stDate}&end=${enDate}`
          }else{
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  dispatch({
                      type: SET_RADIO,
                      payload: { stations: response.data.data.results }
                  });
              })
              .catch((error) => {
                  console.log(`radio station response error ::::: ${JSON.stringify(error)}`);
              });
        },
        getStationWatchTime(dispatch, stationId, stDate, enDate) {
          let baseUrl = `${configData.afroServer}stationsessions/watchtime/${stationId}`
          if(stDate  && enDate ){
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          console.log(`called get station watchtime ${stationId}`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  console.log(` got station views ${response.data.length}`);
                  dispatch({
                      type: SET_WATCH_TIME,
                      payload: { watchTime: response.data.average_watch_time }
                  });
              })
              .catch((error) => {
                dispatch({
                  type: SET_WATCH_TIME,
                  payload: { watchTime: 'N/A' }
              });
                  console.log(`getwatchtime response error ::::: ${JSON.stringify(error)}`);
              });
        },
        getStationDailyViewership(dispatch, stationId, stDate, enDate) {
          let baseUrl = `${configData.afroServer}stationsessions/daily/${stationId}`
          if(stDate  && enDate ){
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          dispatch({
            type: LOADING_VIEWS,
            payload : {isHourly:false}
          });
          console.log(`called getStationDailyViewership ${stationId}`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  // console.log(` got station views ${response.data.length}`);
                  dispatch({
                      type: SET_DAILY_VIEWS,
                      payload: { views: response.data }
                  });
              })
              .catch((error) => {
                dispatch({
                  type: SET_DAILY_VIEWS,
                  payload: { views: [] }
              });
                  console.log(`getStationDailyViewership response error ::::: ${JSON.stringify(error)}`);
              });
        },
        getStationHourlyViewership(dispatch, stationId, stDate) {
          let baseUrl = `${configData.afroServer}stationsessions/hourly/${stationId}`
          if(stDate ){
            baseUrl += `?start=${stDate}`
          }
          dispatch({
            type: LOADING_VIEWS,
            payload : {isHourly:true}
          });
          console.log(`called getStationHourlyViewership ${stationId}`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                 // console.log(` got station views ${response.data.length}`);
                  dispatch({
                      type: SET_HOURLY_VIEWS,
                      payload: { views: response.data }
                  });
              })
              .catch((error) => {
                dispatch({
                  type: SET_HOURLY_VIEWS,
                  payload: { views: [] }
              });
                  console.log(`getStationHourlyViewership response error ::::: ${JSON.stringify(error)}`);
              });
        },

        getStationActiveViewers(dispatch, stationId) {
          let baseUrl = `${configData.afroServer}stationsessions/active/${stationId}`
          console.log(`called getStationActiveViewers ${stationId}`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                 // console.log(` got station views ${response.data.length}`);
                  dispatch({
                      type: SET_ACTIVE_VIEWERS,
                      payload: { viewers: response.data.active_views_count }
                  });
              })
              .catch((error) => {
                dispatch({
                  type: SET_ACTIVE_VIEWERS,
                  payload: { viewers: 'N/A' }
              });
                  console.log(`getStationActiveViewers response error ::::: ${JSON.stringify(error)}`);
              });
        },
        getStationQuickies(dispatch, stationId, stDate, enDate) {
          let baseUrl = `${configData.afroServer}quickies/quickies/${stationId}`
          if(stDate  && enDate ){
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          console.log(`called get station quickies ${stationId}`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  dispatch({
                      type: SET_QUICKIES,
                      payload: { quickies: response.data.results }
                  });
              })
              .catch((error) => {
                  console.log(`getStationQuickies response error ::::: ${JSON.stringify(error)}`);
              });
        },
        getStationVideos(dispatch, stationId, stDate, enDate) {
          let baseUrl = `${configData.afroServer}ondemand/videos/${stationId}`
          if(stDate  && enDate ){
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          console.log(`called get station videos ${stationId}`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  dispatch({
                      type: SET_VIDEOS,
                      payload: { videos: response.data.results }
                  });
              })
              .catch((error) => {
                  console.log(`getStationVideos response error ::::: ${JSON.stringify(error)}`);
              });
        }
    },
    content: {
        getPublications(dispatch, stDate, enDate) {
          let baseUrl = `${configData.afroServer}feeds/`
          if(stDate  && enDate ){
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          console.log(`called getPublications`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`,
                  }
              })
              .then((response) => {
                  dispatch({
                      type: SET_PUBLICATIONS,
                      payload: { news: response.data.data.results }
                  });
              })
              .catch((error) => {
                  console.log(`getPublications response error ::::: ${JSON.stringify(error)}`);
              });
        },
        getMusic(dispatch, stDate, enDate) {
          let baseUrl = `${configData.afroServer}djmixes/playlist/all`
          if(stDate  && enDate ){
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          console.log(`called getMusic`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  dispatch({
                      type: SET_MUSIC,
                      payload: { music: response.data.data.results }
                  });
              })
              .catch((error) => {
                  console.log(`getMusic response error ::::: ${JSON.stringify(error)}`);
              });
        },
        getAds(dispatch, stDate, enDate) {
          let baseUrl = `${configData.afroServer}adverts/ads/stats`
          if(stDate  && enDate ){
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          console.log(`called getAds`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  dispatch({
                      type: SET_ADS,
                      payload: { ads: response.data.results }
                  });
              })
              .catch((error) => {
                  console.log(`getAds response error ::::: ${JSON.stringify(error)}`);
              });
        },
        getPodcasts(dispatch, stDate, enDate) {
          let baseUrl = `${configData.afroServer}podcasts/all`
          if(stDate  && enDate ){
            baseUrl += `?start=${stDate}&end=${enDate}`
          }
          console.log(`called getPodcasts`);
          axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  dispatch({
                      type: SET_PODS,
                      payload: { podcasts: response.data.data.results }
                  });
              })
              .catch((error) => {
                  console.log(`getPodcasts response error ::::: ${JSON.stringify(error)}`);
              });
        },
        async getLikesCount(id,content) {
          let baseUrl = `${configData.afroServer}likes/${content}/${id}/likedCount`
          return axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  return response.data.count
              })
              .catch((error) => {
                  console.log(` ${content} likes count error ::::: ${JSON.stringify(error)}`);
                  return null
              });
        },
        async getDislikesCount(id,content) {
          let baseUrl = `${configData.afroServer}likes/${content}/${id}/dislikedCount`
          return axios
              .get(baseUrl, {
                  headers: {
                      Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                  }
              })
              .then((response) => {
                  return response.data.count
              })
              .catch((error) => {
                  console.log(` ${content} likes count error ::::: ${JSON.stringify(error)}`);
                  return null
              });
        },
    },
    movies:{
      getMovieStats(dispatch, stDate, enDate) {
        let baseUrl = `${configData.afroServer}movies/stats`
        if(stDate  && enDate ){
          baseUrl += `?start=${stDate}&end=${enDate}`
        }
        console.log(`called getMovieStats`);
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`fetched getMovieStats ${JSON.stringify(response.data.stats)}`);
                dispatch({
                    type: SET_STATS,
                    payload: { stats: response.data.stats }
                });
            })
            .catch((error) => {
                console.log(`getMovieStats response error ::::: ${JSON.stringify(error)}`);
            });
      },
      async getMovies(dispatch,page=1) {
        // dispatch({
        //     type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = `${configData.afroServer}movies/all/stats?page=${page}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SET_MOVIES,
                    payload: { movies: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`getMovies error ::::: ${JSON.stringify(error)}`);
            });
      },
      getShowStats(dispatch, stDate, enDate) {
        let baseUrl = `${configData.afroServer}movies/stats/shows`
        if(stDate  && enDate ){
          baseUrl += `?start=${stDate}&end=${enDate}`
        }
        console.log(`called getShowStats`);
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`fetched getShowStats ${JSON.stringify(response.data.stats)}`);
                dispatch({
                    type: SET_SHOW_STATS,
                    payload: { stats: response.data.stats }
                });
            })
            .catch((error) => {
                console.log(`getShowStats response error ::::: ${JSON.stringify(error)}`);
            });
      },
      async filterMovies(dispatch,field,value,page,type) {
        // dispatch({
        //   type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = `${configData.afroServer}movies/filter/stats?page=${page}&field=${field}&value=${value}&type=${type}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SET_MOVIES,
                    payload: { movies: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`filter movies error ::::: ${JSON.stringify(error)}`);
            });
      },
      async getShows(dispatch,page=1) {
        // dispatch({
        //     type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = `${configData.afroServer}movies/all/stats/shows?page=${page}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SET_SHOWS,
                    payload: { shows: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`getShows error ::::: ${JSON.stringify(error)}`);
            });
      },
      async filterShows(dispatch,field,value,page,type) {
        // dispatch({
        //   type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = `${configData.afroServer}movies/filter/stats/shows?page=${page}&field=${field}&value=${value}&type=${type}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SET_SHOWS,
                    payload: { shows: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`filter shows error ::::: ${JSON.stringify(error)}`);
            });
      },
      async getSeasons(dispatch,showId) {
        let baseUrl = `${configData.afroServer}movies/${showId}/seasons`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SET_SEASONS,
                    payload: { seasons: response.data.seasons}
                });
            })
            .catch((error) => {
                console.log(`getSeasons error ::::: ${JSON.stringify(error)}`);
                return error
            });
      },
      async getEpisodes(dispatch,seasonId) {
        let baseUrl = `${configData.afroServer}movies/season/${seasonId}/episodes`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SET_EPISODES,
                    payload: { episodes: response.data.episodes }
                });
            })
            .catch((error) => {
                console.log(`getEpisodes error ::::: ${JSON.stringify(error)}`);
            });
      },
    },
    users: {
        getUsers(dispatch, token) {
            axios
                .get(`${configData.apiServer}users`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: SET_USERS,
                        payload: { users: response.data }
                    });
                })
                .catch((error) => {
                    console.log(`get users response error ::::: ${JSON.stringify(error)}`);
                });
        },
        registerUser(dispatch,data,dismiss) {
            const user = {
              role: data.role,
              password: data.password,
              lastName: data.lastName,
              firstName: data.firstName,
              email: data.email,
              company: data.company
            }
            axios
                .post(`${configData.apiServer}users/register`, user)
                .then((response) => {
                    dismiss(true)
                    dispatch({
                      type: REGISTER
                  });
                })
                .catch((error) => {
                    console.log(`register users response error ::::: ${JSON.stringify(error)}`);
                });
        },
    },
    rentals: {
      async getRentals(dispatch,page=1) {
        // dispatch({
        //     type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = `${configData.afroServer}movies/rentals?page=${page}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              //console.log(`response ::: ${response.data.data}`)
                dispatch({
                    type: SET_RENTALS,
                    payload: { rentals: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`getSubscriptions error ::::: ${JSON.stringify(error)}`);
            });
      },
      async filterRentals(dispatch,field,value,page) {
        // dispatch({
        //   type: CLEAR_RENTALS
        // });
        let baseUrl = field === "date_rented" ? `${configData.afroServer}movies/filterRentals?page=${page}&field=${field}&start=${value[0]}&end=${value[1]}` : `${configData.afroServer}movies/filterRentals?page=${page}&field=${field}&value=${value}`
        return axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SET_RENTALS,
                    payload: { rentals: response.data.data }
                });
                return response.data.data
            })
            .catch((error) => {
                console.log(`filter rentals error ::::: ${JSON.stringify(error)}`);
            });
      },
      async getRentalTransactions(dispatch, id) {
        let baseUrl = `${configData.afroServer}movies/get_rental_transactions?id=${id}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`response ::: ${response.data.data.results}`)
              if(response.status === 200)
                dispatch({
                    type: SET_RENTAL_TRANSACTIONS,
                    payload: { transactions: response.data.data.results }
                });
            })
            .catch((error) => {
                console.log(`get rental transactions error ::::: ${JSON.stringify(error)}`);
            });
      },
      async updateRental(dispatch, id, date_rented) {
        const data = {
          date_rented
        }
        return axios
            .put(`${configData.afroServer}movies/update/${id}`, data, {
              headers: {
                  Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
              }
          } )
            .then((response) => {
                if(response.status === 200) {
                  dispatch({
                    type: RENTAL_PAID,
                    payload: { rental: response.data }
                  });
                  return {data : response.data, message: "Success"}
                }
                else
                  return {message : `Operation Failed ${response.status}`}
            })
            .catch((error) => {
                console.log(`update  rental response error ::::: ${JSON.stringify(error)}`);
                return {message : "Failed"}
            });
      },
      async getRentalSummary(dispatch,start,end) {
        dispatch({
            type: GET_RENTAL_SUMMARY
        });
        let baseUrl = `${configData.afroServer}movies/summary?start=${start}&end=${end}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`response ::: ${response.data}`)
                dispatch({
                    type: SET_RENTAL_SUMMARY,
                    payload: { summary: response.data }
                });
            })
            .catch((error) => {
                console.log(`get summary error ::::: ${JSON.stringify(error)}`);
            });
      },
    },
    payments: {
      async getSubscriptions(dispatch,page=1) {
        // dispatch({
        //     type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = `${configData.afroServer}subscription/all?page=${page}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`response ::: ${response.data.data}`)
                dispatch({
                    type: SET_SUBSCRIPTIONS,
                    payload: { subscriptions: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`getSubscriptions error ::::: ${JSON.stringify(error)}`);
            });
      },
      async filterSubscriptions(dispatch,field,value,page) {
        // dispatch({
        //   type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = field === "dateSubscribed" ? `${configData.afroServer}subscription/filter?page=${page}&field=${field}&start=${value[0]}&end=${value[1]}` : `${configData.afroServer}subscription/filter?page=${page}&field=${field}&value=${value}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`response ::: ${response.data.data}`)
                dispatch({
                    type: SET_SUBSCRIPTIONS,
                    payload: { subscriptions: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`filter Subscriptions error ::::: ${JSON.stringify(error)}`);
            });
      },
      async getUserTransactions(dispatch, email) {
        let baseUrl = `${configData.afroServer}subscription/transactions/${email}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              if(response.status === 200)
                dispatch({
                    type: SET_TRANSACTIONS,
                    payload: { transactions: response.data }
                });
            })
            .catch((error) => {
                console.log(`getUserTransactions error ::::: ${JSON.stringify(error)}`);
            });
      },
      async forcePayment(dispatch, user_id, duration, location, dateSubscribed) {
        const subscription = {
          duration,location,dateSubscribed,subscribed: true, status:"paid"
        }
        console.log(subscription)
        return axios
            .put(`${configData.afroServer}subscription/update/${user_id}`, subscription, {
              headers: {
                  Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
              }
          } )
            .then((response) => {
                if(response.status === 200) {
                  dispatch({
                    type: PAID,
                    payload: { subscription: response.data }
                  });
                  return {data : response.data, message: "Success"}
                }
                else
                  return {message : `Operation Failed ${response.status}`}
            })
            .catch((error) => {
                console.log(`force payment response error ::::: ${JSON.stringify(error)}`);
                return {message : "Failed"}
            });
      },
      async checkStatus(requestToken,afmobileToken, msisdn) {
        const payload = {
          "requestToken" : requestToken ?? "",
          afmobileToken,
          msisdn
        }
        //console.log(`payload check status ${JSON.stringify(payload)}`)
        return axios
            .post(`${configData.afroServer}subscription/check_status`, payload, {
              headers: {
                  Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
              }
          } )
            .then((response) => {
                if(response.status === 200) {
                  return response.data.message
                }
                else
                  return {message : `Operation Failed ${response.status}`}
            })
            .catch((error) => {
                console.log(`checkStatus error ::::: ${JSON.stringify(error)}`);
                return {message : "Failed"}
            });
      },


      async checkStatusFwv(transactionToken) {
        return axios
            .get(`${configData.afroServer}subscription/status_fwv/${transactionToken}`, {
              headers: {
                  Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
              }
          } )
            .then((response) => {
                if(response.status === 200) {
                  return response.data.message
                }
                else
                  return {message : `Operation Failed ${response.status}`}
            })
            .catch((error) => {
                console.log(`checkStatusFwv error ::::: ${JSON.stringify(error)}`);
                return {message : "Failed"}
            });
      },
      async editSubscription(dispatch ,subscription) {

        return axios
            .put(`${configData.afroServer}subscription/update/${subscription.user_id}`, subscription, {
              headers: {
                  Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
              }
          } )
            .then((response) => {
                if(response.status === 200) {
                  dispatch({
                    type: PAID,
                    payload: { subscription: response.data }
                  });
                  return {data : response.data, message: "Success"}
                }
                else
                  return {message : `Operation Failed ${response.status}`}
            })
            .catch((error) => {
                console.log(`edit subscription response error ::::: ${JSON.stringify(error)}`);
                return {message : "Failed"}
            });
      },
      async getSummary(dispatch,start,end) {
        dispatch({
            type: GET_SUMMARY
        });
        let baseUrl = `${configData.afroServer}subscription/summary?start=${start}&end=${end}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`response ::: ${response.data}`)
                dispatch({
                    type: SET_SUMMARY,
                    payload: { summary: response.data }
                });
            })
            .catch((error) => {
                console.log(`get summary error ::::: ${JSON.stringify(error)}`);
            });
      },
      async getReport(dispatch,start,end,mno,ext) {
        dispatch({
            type: GET_REPORT
        });
        let baseUrl = `${configData.afroServer}${ext}/report?start=${start}&end=${end}&mno=${mno}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              if(response.data.message != null){
                console.log("no data")
                dispatch({
                  type: SET_REPORT,
                  payload: { csv: null }
                });
              }else{
                console.log("got report data")
              dispatch({
                type: SET_REPORT,
                payload: { csv: response }
              });
            }

            })
            .catch((error) => {
              dispatch({
                type: REPORT_ERROR,
              });
              console.log(`get report error ::::: ${JSON.stringify(error)}`);
            });
      },


    },

    bundles: {
      async getBundleSubscriptions(dispatch,page=1) {
        // dispatch({
        //     type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = `${configData.afroServer}mtnbundles/subscriptions/all?page=${page}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              //console.log(`response ::: ${response.data.data}`)
                dispatch({
                    type: SET_BUNDLE_SUBSCRIPTIONS,
                    payload: { bundleSubscriptions: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`get bundle Subscriptions error ::::: ${JSON.stringify(error)}`);
            });
      },
      async filterBundleSubscriptions(dispatch,field,value,page) {
        // dispatch({
        //   type: CLEAR_RENTALS
        // });
        let baseUrl = (field === "start_date" || field === "end_date") ? `${configData.afroServer}mtnbundles/filterSubscriptions?page=${page}&field=${field}&start=${value[0]}&end=${value[1]}` : `${configData.afroServer}mtnbundles/filterSubscriptions?page=${page}&field=${field}&value=${value}`
        return axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SET_BUNDLE_SUBSCRIPTIONS,
                    payload: { bundleSubscriptions: response.data.data }
                });
                return response.data.data
            })
            .catch((error) => {
                console.log(`filter bundleSubscriptions error ::::: ${JSON.stringify(error)}`);
            });
      },
      async getbundleTransactions(dispatch, userId) {
        let baseUrl = `${configData.afroServer}mtnbundles/getUserTransactions/${userId}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              // console.log(`response ::: ${JSON.stringify(response.data[0])}`)
              if(response.status === 200)
               {
                console.log("send set bundle")
                dispatch({
                    type: SET_BUNDLE_TRANSACTIONS,
                    payload: { transactions: response.data }
                });
              }
            })
            .catch((error) => {
                console.log(`get bundle transactions error ::::: ${JSON.stringify(error)}`);
            });
      },
      async updateBundleSubscription(dispatch,id, bundleId, start) {
        const data = {
          start,
          bundleId
        }
        return axios
            .put(`${configData.afroServer}mtnbundles/update/${id}`, data, {
              headers: {
                  Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
              }
          } )
            .then((response) => {
                if(response.status === 200) {
                  dispatch({
                    type: BUNDLE_PAID,
                    payload: { bundleSubscription: response.data }
                  });
                  return {data : response.data, message: "Success"}
                }
                else
                  return {message : `Operation Failed ${response.status}`}
            })
            .catch((error) => {
                console.log(`updateBundleSubscription response error ::::: ${JSON.stringify(error)}`);
                return {message : "Failed"}
            });
      },
      async getBundleSummary(dispatch,start,end) {
        dispatch({
            type: GET_BUNDLE_SUMMARY
        });
        let baseUrl = `${configData.afroServer}mtnbundles/summary?start=${start}&end=${end}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`response ::: ${response.data}`)
                dispatch({
                    type: SET_BUNDLE_SUMMARY,
                    payload: { summary: response.data }
                });
            })
            .catch((error) => {
                console.log(`getBundleSummary error ::::: ${JSON.stringify(error)}`);
            });
      },
      async checkStatus(dispatch,afmobileToken) {
        let baseUrl = `${configData.afroServer}mtnbundles/status/${afmobileToken}`
        return axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              if(response.status === 200) {
                dispatch({
                  type: BUNDLE_PAID,
                  payload: { bundleSubscription: response.data }
                });
                return { message : response.data }
              }
              else
                return { message : `Operation Failed ${response.status}` }
            })
            .catch((error) => {
                console.log(`getBundleSummary error ::::: ${JSON.stringify(error)}`);
            });
      },

    },

    liveEvents: {

      async getEventPayments(dispatch,page=1) {
        // dispatch({
        //     type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = `${configData.afroServer}events/payments?page=${page}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              //console.log(`response ::: ${response.data.data}`)
                dispatch({
                    type: SET_EVENT_PAYMENTS,
                    payload: { eventPayments: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`getEventPayments error ::::: ${JSON.stringify(error)}`);
            });
      },
      async getEvents(dispatch,page=1) {
        // dispatch({
        //     type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = `${configData.afroServer}events/all/dash?page=${page}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              //console.log(`response ::: ${response.data.data}`)
                dispatch({
                    type: SET_EVENTS,
                    payload: { events: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`getEvents error ::::: ${JSON.stringify(error)}`);
            });
      },
      async getEventStats(dispatch) {
        let baseUrl = `${configData.afroServer}events/stats`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              //console.log(`response ::: ${response.data.data}`)
                dispatch({
                    type: GOT_STATS,
                    payload: { stats: response.data.stats }
                });
            })
            .catch((error) => {
                console.log(`getEventStats error ::::: ${JSON.stringify(error)}`);
            });
      },
      async filterEvents(dispatch,field,value,page) {
        // dispatch({
        //     type: CLEAR_SUBSCRIPTIONS
        // });
        let baseUrl = (field === "start" || field === "end") ? `${configData.afroServer}events/filter/dash?page=${page}&field=${field}&start=${value[0]}&end=${value[1]}` : `${configData.afroServer}events/filter/dash?page=${page}&field=${field}&value=${value}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              //console.log(`response ::: ${response.data.data}`)
                dispatch({
                    type: SET_EVENTS,
                    payload: { events: response.data.data }
                });
            })
            .catch((error) => {
                console.log(`getEvents error ::::: ${JSON.stringify(error)}`);
            });
      },
      async filterPayments(dispatch,field,value,page) {
        // dispatch({
        //   type: CLEAR_RENTALS
        // });
        let baseUrl = (field === "start_date" || field === "end_date") ? `${configData.afroServer}events/filterPayments?page=${page}&field=${field}&start=${value[0]}&end=${value[1]}` : `${configData.afroServer}events/filterPayments?page=${page}&field=${field}&value=${value}`
        return axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SET_EVENT_PAYMENTS,
                    payload: { eventPayments: response.data.data }
                });
                return response.data.data
            })
            .catch((error) => {
                console.log(`filterPayments error ::::: ${JSON.stringify(error)}`);
            });
      },

      async geteventPaymentTransactions(dispatch, id) {
        let baseUrl = `${configData.afroServer}events/getTransactions?id=${id}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`response ::: ${response.data.data.results}`)
              if(response.status === 200)
                dispatch({
                    type: SET_EVENT_PAYMENT_TRANSACTIONS,
                    payload: { transactions: response.data.data.results }
                });
            })
            .catch((error) => {
                console.log(`geteventPaymentTransactions error ::::: ${JSON.stringify(error)}`);
            });
      },
      async setEventPaid(dispatch, id) {
        return axios
            .put(`${configData.afroServer}events/setPaid/${id}`, {
              headers: {
                  Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
              }
          } )
            .then((response) => {
                if(response.status === 200) {
                  dispatch({
                    type: EVENT_PAID,
                    payload: { eventPayment: response.data }
                  });
                  return {data : response.data, message: "Success"}
                }
                else
                  return {message : `Operation Failed ${response.status}`}
            })
            .catch((error) => {
                console.log(`setEventPaid error ::::: ${JSON.stringify(error)}`);
                return {message : "Failed"}
            });
      },
      async getPaymentSummary(dispatch,start,end) {
        dispatch({
            type: GET_EVENT_PAYMENT_SUMMARY
        });
        let baseUrl = `${configData.afroServer}events/summary?start=${start}&end=${end}`
        axios
            .get(baseUrl, {
                headers: {
                    Authorization: `Token ${process.env.REACT_APP_ADMIN_TOKEN}`
                }
            })
            .then((response) => {
              console.log(`response ::: ${response.data}`)
                dispatch({
                    type: SET_EVENT_PAYMENT_SUMMARY,
                    payload: { summary: response.data }
                });
            })
            .catch((error) => {
                console.log(`getPaymentSummary error ::::: ${JSON.stringify(error)}`);
            });
      },
    }

};

export default apiModel;
