import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/users')));

const PaymentsPage = Loadable(lazy(() => import('views/payments')));

const RentalsPage = Loadable(lazy(() => import('views/rentals')));

const BundlesPage = Loadable(lazy(() => import('views/bundles')));

const MoviesPage = Loadable(lazy(() => import('views/media/movies')));

const ShowsPage = Loadable(lazy(() => import('views/media/shows')));

const LiveEventsPage = Loadable(lazy(() => import('views/live_events')));

const EventStatsPage = Loadable(lazy(() => import('views/media/events')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/users',
            element: <SamplePage />
        },
        {
            path: '/payments',
            element: <PaymentsPage />
        },
        {
            path: '/rentals',
            element: <RentalsPage />
        },
        {
          path: '/bundles',
          element: <BundlesPage />
        },
        {
            path: '/movies',
            element: <MoviesPage />
        },
        {
            path: '/shows',
            element: <ShowsPage />
        },
        {
          path: '/events',
          element: <LiveEventsPage />
        },
        {
          path: '/eventstats',
          element: <EventStatsPage />
        },
    ]
};

export default MainRoutes;
