// assets
import { IconBrandChrome, IconCurrencyBitcoin } from '@tabler/icons';


const media = {
    id: 'media-group',
    title: 'Media',
    type: 'group',
    children: [
        {
            id: 'movies',
            title: 'Movies',
            type: 'item',
            url: '/movies',
            icon: IconBrandChrome,
            breadcrumbs: true
        },
        {
          id: 'shows',
          title: 'Shows',
          type: 'item',
          url: '/shows',
          icon: IconCurrencyBitcoin,
          breadcrumbs: true
      },
      {
        id: 'eventstats',
        title: 'Events',
        type: 'item',
        url: '/eventstats',
        icon: IconCurrencyBitcoin,
        breadcrumbs: true
    }
    ]
};

export default media;
