import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import accountReducer from './accountReducer';
import contentReducer from './contentReducer';
import customizationReducer from './customizationReducer';
import stationsReducer from './stationsReducer';
import subscriptionsReducer from './subscriptionsReducer';
import rentalsReducer from './rentalsReducer';
import movieReducer from './moviesReducer';
import bundleReducer from './bundlesReducer';
import liveEventsReducer from './liveEventsReducer';
import reportReducer from './reportReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    account: persistReducer(
        {
            key: 'account',
            storage,
            keyPrefix: 'afmobile-'
        },
        accountReducer
    ),
    payments: persistReducer(
      {
          key: 'payments',
          storage,
          keyPrefix: 'afmobile-'
      },
      subscriptionsReducer
    ),
    rental_reducer: persistReducer(
      {
          key: 'rental_reducer',
          storage,
          keyPrefix: 'afmobile-'
      },
      rentalsReducer
    ),
    bundle_reducer: persistReducer(
      {
          key: 'bundle_reducer',
          storage,
          keyPrefix: 'afmobile-'
      },
      bundleReducer
    ),
    live_events_reducer: persistReducer(
      {
          key: 'live_events_reducer',
          storage,
          keyPrefix: 'afmobile-'
      },
      liveEventsReducer
    ),
    movie_reducer:movieReducer,
    reports:reportReducer,
    stations: stationsReducer,
    content: contentReducer,
    customization: customizationReducer
});

export default reducer;
