// action - state management
import { LOGIN, LOGOUT, REGISTER, SET_USERS } from './actions';

export const initialState = {
    token: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    users: [],
    addedUser: false
};

// -----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
          console.log("fired register")
            return {
                ...state,
                addedUser: true
            };
        }
        case LOGIN: {
            const { user, token } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                token,
                user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                token: '',
                user: null
            };
        }
        case SET_USERS: {
            const { users } = action.payload;
            console.log(` set users :::::: ${JSON.stringify(users)}`);
            return {
                ...state,
                users
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
