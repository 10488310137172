// assets
import { IconBrandChrome, IconCurrencyBitcoin } from '@tabler/icons';


const other = {
    id: 'admin-group',
    title: 'Admin',
    type: 'group',
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        url: '/users',
        icon: IconBrandChrome,
        breadcrumbs: true
      },
      {
        id: 'payments',
        title: 'Payments',
        type: 'item',
        url: '/payments',
        icon: IconCurrencyBitcoin,
        breadcrumbs: true
      },
      {
        id: 'rentals',
        title: 'Rentals',
        type: 'item',
        url: '/rentals',
        icon: IconCurrencyBitcoin,
        breadcrumbs: true
      },
      {
        id: 'bundles',
        title: 'BundleSubscriptions',
        type: 'item',
        url: '/bundles',
        icon: IconCurrencyBitcoin,
        breadcrumbs: true
      },
      {
        id: 'liveEvents',
        title: 'Events',
        type: 'item',
        url: '/events',
        icon: IconCurrencyBitcoin,
        breadcrumbs: true
      }
    ]
};

export default other;
