// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const SET_USERS = 'SET_USERS';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - station reducer
export const SET_TV = 'SET_TV';
export const SET_ACTIVE = 'SET_ACTIVE';
export const SET_ACTIVE_VIEWERS = 'SET_ACTIVE_VIEWERS';
export const SET_RADIO = 'SET_RADIO';
export const SET_WATCH_TIME = 'SET_WATCH_TIME';
export const SET_DAILY_VIEWS = 'SET_DAILY_VIEWS';
export const SET_HOURLY_VIEWS = 'SET_HOURLY_VIEWS';
export const SET_QUICKIES = 'SET_QUICKIES';
export const SET_VIDEOS = 'SET_VIDEOS';
export const STATION_CHANGED = 'STATION_CHANGED';
export const LOADING_VIEWS = 'LOADING_VIEWS';

export const SET_MUSIC = 'SET_MUSIC';
export const SET_PODS = 'SET_PODS';
export const SET_PUBLICATIONS = 'SET_PUBLICATIONS';
export const SET_NEW_USERS = 'SET_NEW_USERS';
export const SET_ACTIVE_USERS = 'SET_ACTIVE_USERS';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_SUMMARY = 'SET_SUMMARY';
export const SET_REPORT = 'SET_REPORT';
export const GET_SUMMARY = 'GET_SUMMARY';
export const GET_REPORT = 'GET_REPORT';
export const REPORT_ERROR = 'REPORT_ERROR';
export const CLEAR_REPORT = 'CLEAR_REPORT';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const PAID = 'PAID';
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';
export const CLEAR_SUBSCRIPTIONS = 'CLEAR_SUBSCRIPTIONS';

export const SET_RENTALS = 'SET_RENTALS';
export const SET_RENTAL_SUMMARY = 'SET_RENTAL_SUMMARY';
export const GET_RENTAL_SUMMARY = 'GET_RENTAL_SUMMARY';
export const SET_RENTAL_TRANSACTIONS = 'SET_RENTAL_TRANSACTIONS';
export const CLEAR_RENTAL_TRANSACTIONS = 'CLEAR_RENTAL_TRANSACTIONS';
export const CLEAR_RENTALS = 'CLEAR_RENTALS';
export const RENTAL_PAID = 'RENTAL_PAID';
export const SET_ADS = 'SET_ADS';

//movies
export const SET_STATS = 'SET_STATS';
export const SET_SHOW_STATS = 'SET_SHOW_STATS';
export const SET_MOVIES = 'SET_MOVIES';
export const SET_SHOWS = 'SET_SHOWS';
export const CLEAR_MOVIES = 'CLEAR_MOVIES';
export const CLEAR_SHOWS = 'CLEAR_SHOWS';
export const SET_SEASONS = 'GET_SEASONS';
export const SET_EPISODES = 'GET_EPISODES';
export const CLEAR_SEASONS = 'CLEAR_SEASONS';
export const CLEAR_EPISODES = 'CLEAR_EPISODES';
export const RESET_SEASON_FILTERS = 'RESET_SEASON_FILTERS';
export const RESET_EPISODE_FILTERS = 'RESET_EPISODE_FILTERS';

// mtn_bundles
export const SET_BUNDLE_SUBSCRIPTIONS = 'SET_BUNDLE_SUBSCRIPTIONS';
export const SET_BUNDLE_SUMMARY = 'SET_BUNDLE_SUMMARY';
export const GET_BUNDLE_SUMMARY = 'GET_BUNDLE_SUMMARY';
export const SET_BUNDLE_TRANSACTIONS = 'SET_BUNDLE_TRANSACTIONS';
export const CLEAR_BUNDLE_TRANSACTIONS = 'CLEAR_BUNDLE_TRANSACTIONS';
export const CLEAR_BUNDLE_SUBSCRIPTIONS = 'CLEAR_BUNDLE_SUBSCRIPTIONS';
export const BUNDLE_PAID = 'BUNDLE_PAID';

//live_events
export const CLEAR_EVENT_TRANSACTIONS = 'CLEAR_EVENT_TRANSACTIONS';
export const EVENT_PAID = 'EVENT_PAID';
export const SET_EVENT_PAYMENTS = 'SET_EVENT_PAYMENTS';
export const SET_EVENT_PAYMENT_TRANSACTIONS = 'SET_EVENT_PAYMENT_TRANSACTIONS';
export const CLEAR_EVENT_PAYMENTS = 'CLEAR_EVENT_PAYMENTS';
export const SET_EVENT_PAYMENT_SUMMARY = 'SET_EVENT_PAYMENT_SUMMARY';
export const GET_EVENT_PAYMENT_SUMMARY = 'GET_EVENT_PAYMENT_SUMMARY';
export const SET_EVENTS = 'SET_EVENTS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const GOT_STATS = 'GOT_STATS';
export const CLEAR_STATS = 'CLEAR_STATS';
