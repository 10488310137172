import dashboard from './dashboard';
import other from './other';
import moderator from './moderator';
import media from './media';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, other, moderator,media]
};

export default menuItems;
