// action - state management
import { CLEAR_EVENT_TRANSACTIONS, EVENT_PAID, SET_EVENT_PAYMENTS, SET_EVENT_PAYMENT_TRANSACTIONS, CLEAR_EVENT_PAYMENTS, SET_EVENT_PAYMENT_SUMMARY, GET_EVENT_PAYMENT_SUMMARY, SET_EVENTS, CLEAR_EVENTS, CLEAR_STATS, GOT_STATS } from './actions';

export const initialState = {
    eventPayments: {
      count: 0,
      next: "",
      previous: "",
      results: []
    },
    events: {
      count: 0,
      next: "",
      previous: "",
      results: []
    },
    eventPaymentSummary:{
      "airtel" : 0,
      "mtn" : 0,
      "cards" : 0
    },
    stats:{
      "mostViewed": [],
      "paid_for": [],
      "views": 0,
      "upcoming": 0,
      "paid_for_count": 0,
      "total": 0,
      "past": 0
    },
    eventPaymentTransactions: [],
    fetchedEventPayments: false,
    fetchedEventStats: false,
    fetchedEvents: false,
    fetchedEventPaymentTransactions: false,
    eventPaymentSummaryFetched: false,
};

// -----------------------|| ACCOUNT REDUCER ||-----------------------//

const liveEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EVENT_PAYMENT_TRANSACTIONS: {
            const { transactions } = action.payload;
            return {
                ...state,
                fetchedEventPaymentTransactions: true,
                eventPaymentTransactions : transactions
            };
        }
        case SET_EVENT_PAYMENT_SUMMARY: {
          const { summary } = action.payload;
          return {
              ...state,
              eventPaymentSummaryFetched: true,
              eventPaymentSummary : summary
          };
        }
        case GET_EVENT_PAYMENT_SUMMARY: {
          return {
              ...state,
              eventPaymentSummaryFetched: false,
              eventPaymentSummary: {
                "airtel" : 0,
                "mtn" : 0,
                "cards" : 0
              },
          };
        }
        case SET_EVENT_PAYMENTS: {
          const { eventPayments } = action.payload;
          return {
              ...state,
              fetchedEventPayments: true,
              eventPayments: eventPayments
          };
        }
        case SET_EVENTS: {
          const { events } = action.payload;
          return {
              ...state,
              fetchedEvents: true,
              events: events
          };
        }
        case CLEAR_EVENTS: {
          return {
              ...state,
              fetchedEvents: false,
              events: {}
          };
        }
        case EVENT_PAID: {
          const { eventPayment } = action.payload;
          return {
            ...state,
            eventPayments: {
              count: state.eventPayments.count,
              next: state.eventPayments.next,
              previous: state.eventPayments.previous,
              results: state.eventPayments.results.map(eventPaymentB => {
                if(eventPaymentB.id === eventPayment.id){
                  return eventPayment
                }
                return eventPaymentB
              })
            }
          };
        }
        case CLEAR_EVENT_TRANSACTIONS: {
          return {
            ...state,
            fetchedEventPaymentTransactions: false,
            eventPaymentTransactions: []
          };
        }
        case CLEAR_EVENT_PAYMENTS: {
          return {
            ...state,
            fetchedEventPayments: false,
            eventPayments: {
              count: 0,
              next: "",
              previous: "",
              results: []
            }
          };
        }

        case GOT_STATS: {
          const { stats } = action.payload;
          return {
              ...state,
              fetchedEventStats: true,
              stats: stats
          };
        }

        case CLEAR_STATS: {
          return {
            ...state,
            fetchedEventStats: false,
            stats:{
              "mostViewed": [],
              "paid_for": [],
              "views": 0,
              "upcoming": 0,
              "paid_for_count": 0,
              "total": 0,
              "past": 0
            },
          };
        }
        default: {
            return { ...state };
        }
    }
};

export default liveEventsReducer;
