// material-ui
import { Typography } from '@mui/material';

import { useSelector } from 'react-redux';
// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const account = useSelector((state) => state.account);

    const { user } = account;
    let finalMenu = menuItem.items;
    if(user && user.role === 'User'){
      finalMenu = finalMenu.filter((item) => item.id === 'dashboard')
    }
    if(user && user.role === 'Moderator'){
      finalMenu = finalMenu.filter((item) => item.id === 'mod-group')
    }
    if(user && user.role === 'Admin'){
      finalMenu = finalMenu.filter((item) => item.id !== 'mod-group')
    }
    const navItems = finalMenu.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
