// action - state management
import { SET_STATS, SET_MOVIES, CLEAR_MOVIES, SET_SHOWS, SET_SHOW_STATS, CLEAR_SHOWS, RESET_SEASON_FILTERS, RESET_EPISODE_FILTERS, SET_SEASONS, SET_EPISODES } from './actions';

export const initialState = {
    stats: {
      mostViewed:[],
      topRated:[],
      watchTime:[],
      wishListed:[],
      views:0,
      total: 0,
      active: 0,
      totalRented: 0,
    },
    movies: {
      count: 0,
      next: "",
      previous: "",
      results: []
    },
    seasons: [],
    episodes: [],
    showStats:{
      mostViewed:[],
      topRated:[],
      watchTime:[],
      wishListed:[],
      views:0,
      total: 0,
      active: 0,
      totalRented: 0,
    },
    shows:{
      count: 0,
      next: "",
      previous: "",
      results: []
    },
    fetchedMovies: false,
    fetchedShows: false,
    fetchedSeasons: false,
    fetchedEpisodes: false,
    statsFetched: false,
    showStatsFetched: false,
};

const movieReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_STATS: {
        const { stats } = action.payload;
        return {
            ...state,
            statsFetched: true,
            stats
        };
      }
      case SET_MOVIES: {
        const { movies } = action.payload;
        return {
            ...state,
            fetchedMovies: true,
            movies
        };
      }
      case CLEAR_MOVIES: {
        return {
          ...state,
          fetchedMovies: false,
          movies:{}
        };
      }
      case SET_SHOW_STATS: {
        const { stats } = action.payload;
        return {
            ...state,
            showStatsFetched: true,
            showStats: stats
        };
      }
      case SET_SHOWS: {
        const { shows } = action.payload;
        return {
            ...state,
            fetchedShows: true,
            shows
        };
      }
      case CLEAR_SHOWS: {
        return {
          ...state,
          fetchedShows: false,
          shows:{}
        };
      }
      case SET_SEASONS: {
        const { seasons } = action.payload;
        return {
          ...state,
          fetchedSeasons: true,
          seasons
        }
      }
      case SET_EPISODES: {
        const { episodes } = action.payload;
        return {
          ...state,
          fetchedEpisodes: true,
          episodes
        }
      }
      case RESET_SEASON_FILTERS: {
        return {
          ...state,
          fetchedSeasons: false,
          seasons: []
        }
      }
      case RESET_EPISODE_FILTERS: {
        return {
          ...state,
          fetchedEpisodes: false,
          episodes:[]
        }
      }
      default: {
        return { ...state };
      }
    }
};

export default movieReducer;
